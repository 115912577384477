import React, { Fragment } from 'react'
import Main from '../../layouts/Main'
import styles from './Page.module.scss'
import cn from 'classnames'
import {
  Header,
  Hero,
  CategoryIntro,
  Categories
} from '../../components'

const Home = ({ categories }) => <Fragment>
  <CategoryIntro description={'Verum vel tengd um allt land í sumar'} />
  <Categories categories={categories} />
</Fragment>

const Page = ({ pageContext, ...props }) => {
  const { cats } = pageContext

  return (
    <Main>
      <Header />
      <Hero isHomepage />
      <section className={cn('container', styles.container)}>
        <Home path='/' categories={cats} />
      </section>
    </Main>
  )
}

export default Page
